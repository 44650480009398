import React from 'react'
import Phone from '../assets/phone.svg'
import Map from '../assets/map.svg'
import Mail from '../assets/mail.svg'
import facebookIcon from '../assets/facebook.svg'
import instaIcon from '../assets/instagram.svg'
import '../style/footer.css'
import { useNavigate } from 'react-router-dom'

function Footer({scrollTop}) {
  const navigate = useNavigate();

  const navTo = (linkString) => { console.log(linkString)
    scrollTop();
    return navigate(linkString)
  }

  return (
    <div className="footer-container">
      <div className="footer-main-content">
        <div className="church-info-footer">
          <img src="/logo-horizontal.png" alt="Church logo" className="logo-footer"/>
          <div>
            <p>
              Worship Service: <br/>
              Sunday 10:30AM
            </p>
            <div className="footer-contact-cont">
              <p>
                <span><img src={Phone} alt="" /></span> (480) 329-9830
              </p>
              <p>
                <span><img src={Map} alt="" /></span> 745 N Alma School Rd, Mesa, AZ 85201
              </p>
              <p>
                <span><img src={Mail} alt="" /></span>           filambapchurch@aol.com
              </p>
            </div>
          </div>
          <div className="socials-container socials-container-non-home">
            <a href="https://www.facebook.com/FilAmBaptistChurch" target="_blank">
              <img src={facebookIcon} className="icon-social" alt="facebook icon"/>
            </a>
            <a href="https://www.instagram.com/filamintlbaptist/" target="_blank">
              <img src={instaIcon} className="icon-social" alt="instagram icon"/>
            </a>
          </div>
        </div>
        <div className="ministries-container">
          <h5 className="nav-footer-title">Ministries</h5>
          <ul className="footer-list">
            <li onClick={()=>navTo('/children')}>Children</li>
            <li onClick={()=>navTo('/youth')}>Youth</li>
            <li onClick={()=>navTo('/wow')}>Women of the Word</li>
            <li onClick={()=>navTo('/mop')}>Men of Purpose</li>
            <li onClick={()=>navTo('/frontline')}>Frontline Band</li>
          </ul>
        </div>
        <div className="involved-container">
          <h5 className="nav-footer-title">Get <br/> Involved</h5>
          <ul className="footer-list">
            <li onClick={()=>navTo()}>Visit Us</li>
            <li onClick={()=>navTo('/events')}>Events</li>
            <li onClick={()=>navTo()}>Missions</li>
          </ul>
        </div>
      </div>
      <div>
        <p className="copyright-text">© 2024 FAIBC | All Rights Reserved </p>
      </div>
    </div>
      
  )
}

export default Footer