import React from 'react'
import NonHomeBanner from './NonHomeBanner'
import Horizontal from '../assets/horizontal.jpg'
import Footer from './Footer'
import '../style/about.css'


/* Our Community template */
function Church() {
  return (
    <div>
      <NonHomeBanner title='Church' imgSrc="/media-four-1.jpg"/>
      <div className="media-sm-content-cont">
        <div>
          <p className="caption-title caption-title-2">Fil-Am International Baptist Church</p>
          <h2>Who We Are</h2>
          <p>
            Filipino-American International Baptist Church is a family where people are finding real joy and peace, answers for life, help from friends, hope for eternity, and a true relationship with God!
          </p>
          <p>
            What God can do in one person's life is absolutely wonderful and amazing! He can put the pieces back together! So, we invite you to see for yourself! Be our guest this Sunday, and experience the fun, family spirit of Fil-Am International Baptist Church!
          </p>
          <button className="btn-style btn-lg-style">
            Visit Us
          </button>
        </div>
        <div>
          <img src={Horizontal} alt="" />
        </div>
      </div>
      <section className="cover-right-text-overlay-container">
        <div className="about-page-quote-container">
          <q className="noto-serif">
            Seek first His kingdom and His righteousness, and all these things will be given to you as well 
          </q>
          <p>Matthew 6:33</p>
        </div>
      </section>
     
      <section className="media-with-list-container">
        <div className="left-container">
          <img src='/church-bg-remove.png' alt="sample photo" />
        </div>
        <div className="right-container">
          <section>
            <h2 className='montserrat'>
              <img src="/number-one.jpg"/>
                What We Believe In
            </h2>
            <p>
              We believe the Holy Bible is the inspired Word of God to man and is the only basis for any sound of faith. The Bible is inspired, infallible, and the authoritative Word of God. It is the sole authority for our faith and practice. Any other statement of faith is only a guideline in interpreting the written and living Word of God.
            </p>
          </section>
          <section>
            <h2 className='montserrat'>
              <img src="/number-two.jpg"/>
              Our Mission
              </h2>
            <p>
              Our mission is to spread the good news of our Lord Jesus Christ, to build up and strengthen fellow believers in Christ, and to serve, as Christ did, so that people will come to know him.
            </p>
          </section>
        </div>
      </section>
      <div className="media-four-container">
        <div>
          <img src="/frontline-media-1.jpg" alt="" />
          <div className="overlay"></div>
        </div>
        <div>
          <img src="/media-four-1.jpg" alt="" />
        </div>
        <div>
          <img src="/church-page-banner.jpg" alt="" />
        </div>
        <div>
          <img src="/church-banner.jpg" alt="" />
        </div>
      </div>
      {/*<Footer />*/}
    </div>
  )
}

export default Church