import React from 'react'
import Footer from './Footer'
import '../style/pastor-ministry.css'

function Mop() {

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="ministry-container">
        <aside>
          <img src="/noel-headshot.jpg" alt="sample photo" />
          <div>
            <h3 className="montserrat">Noel Lumabas</h3>
            <p className="caption-title">Men of Purpose Leader</p>
            <p className="leader-desc">
              Humbly serving for more than {currentYear - 2017} years as a faithful Men of Purpose President and a Trustee of Fil-Am. 
            </p>
          </div>
        </aside>
        <div className='ministry-content-cont'>
          <section>
            <figure>
              <img src="/men-of-purpose.png" alt="Men of Purpose ministry in front of Fil-Am Baptist Church" />
              <figcaption className='caption-title'>Men of Purpose ministry in front of Fil-Am Baptist Church</figcaption>
            </figure>
            <h2 className="montserrat">Men of Purpose</h2>
            <div className="quote-container quote-container-2">
            <q>
              "How wonderful and pleasant it is when brothers live together in harmony!"
              <br/>
              Psalm 133:1
            </q>
          </div>
            <h3>Key Values For Men's Outreach:</h3>
            <ol>
              <li>
                <span className='value-title'>Achieve their purpose</span> - To live a holy life pleasing to God
              </li>
              <li>
                <span className='value-title'>Pursue excellence</span> - To do what you can do in an excellent manner that glorifies God.
              </li>
              <li>
                <span className='value-title'>Do guy stuff</span> - Not to be afraid to put the "man" back in men's ministry and have some fun.
              </li>
              <li>
                <span className='value-title'>Use tools</span> - FAIBC Men of purpose uses tools to help them get ahead.
              </li>
              <li>
                <span className='value-title'>Issue a challenge</span> - Challenge men to dream big and to dare to live out God's purpose for themselves and reach other men for Christ.
              </li>
              <li>
                <span className='value-title'>Create a community</span> - To create a structure of men's community, to encourage each other, hold one another accountable, reach out to their friends, and study the Bible's teaching.
              </li>
              <li>
                <span className='value-title'>Battle with Prayer</span> - M.O.P. knows that the key to winning the battle is to fight it on their knees. Focusing on outreach can be overwhelming but through prayer, outreach can be more successful than men ever dreamed possible.
              </li>
              <li>
                <span className='value-title'>Pursue God's Word</span> - T.E.A.M. the Word of God  which means: Teach it, encourage it, Apply it, Model it.
              </li>
            </ol>
            <p>
              Jesus came "to seek and to save that which was lost" Luke 19:10. And in Matthew 25:40, Jesus tells us, "Whatever you did for one of the least of these brothers of mine, you did for me." These are the simple goals of Men of Purpose.
            </p>
          </section>
        </div>
      </div>
      {/*<Footer />*/}
    </>
  )
}

export default Mop