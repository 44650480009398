import React from 'react'
import '../style/home.css'
import Footer from './Footer'
import '../style/about.css'

export default function Home() {
  return (
    <div className='home-container'>
      <div className='home-hero-wrap'>
        {/* <img src='/minis-1.jpg'/> */}
        <img src='/church-page-banner.jpg'/>
        <div className='home-hero-txt montserrat'>
          JOIN THE CHURCH
        </div>
      </div>
      <div className='section-container'>
        <div className='three-img-row-sec'>
          <div className='img-row-sec-item'>
            <div className='home-sec-img-container'>
              <img src='/wow-media-1.jpg'/>
              <h3 className='img-row-txt montserrat'>
                {/* TXT PLACEHOLDER */}
                To Learn
              </h3>
            </div>
          </div>
          <div className='img-row-sec-item'>
            <div className='home-sec-img-container'>
              <img src='/children-media-1.jpg'/>
              <h3 className='img-row-txt montserrat'>
                {/* PLACEHOLDER TXT */}
                To Grow
              </h3>
            </div>
          </div>
          <div className='img-row-sec-item'>
            <div className='home-sec-img-container'>
              <img src='/wow-media-2.jpg'/>
              <h3 className='img-row-txt montserrat'>
                 {/* TXT PLACEHOLDER */}
                 To Fellowship
              </h3>
            </div>
          </div>
        </div>
        <div className='quote-container quote-container-1'>
          <q>
            One God. One language. One desire and adoration- to praise and worship Him, in spirit and in truth.
          </q>
        </div>
        <div className="media-lg-content-container">
          <div className="media-container">
            <img src="/church-banner.jpg" alt="" />
          </div>
          <div>
            <p className='caption-title'>Grow with us</p>
            <h2>Service Times:</h2>
            <ul className="service-list-time ">
              <li>9:30 AM Sunday School</li>
              <li>10:30 AM Sunday Worship Service</li>
              <li>11:00 AM Children Sunday School</li>
              <li>7:00 PM Wednesday Mid-Week Service</li>
              <li>7:00 PM Friday Bible Study</li>
              <li>7:00 PM Saturday Bible Study</li>
            </ul>
          </div>
        </div>
      </div>
      {/*<Footer />*/}
    </div>
  )
}