import React from 'react'

function NonHomeBanner(props) {
  return (
    <>
      <div 
        className="banner-container" 
        style={{backgroundImage: `url(${props.imgSrc})`}}
      >
        <h1 className="page-title">{props.title}</h1>
      </div>
    </>
  )
}

export default NonHomeBanner