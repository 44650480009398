import React from 'react'
import Footer from './Footer'
import '../style/pastor-ministry.css'

function Wow() {

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="ministry-container">
        <aside>
          <img src="/linette-headshot.jpg" alt="sample photo" />
          <div>
            <h3 className="montserrat">Linette Arce</h3>
            <p className="caption-title">Matriarch of W.O.W.</p>
            <p className="leader-desc">
              Linette Arce has served for {currentYear - 2014} years as a Women of the Word Leader.
            </p>
          </div>
        </aside>
        <div className='ministry-content-cont'>
          <section>
            <figure>
              <img src="/wow-horizontal.jpg" alt="Women of the Word in front of Fil-Am International Baptist Church" />
              <figcaption className='caption-title'>W.O.W Ministry in front of Fil-Am Baptist Church</figcaption>
            </figure>
            <h2 className="montserrat">Women of the Word</h2>
            <p>
              The WOW ministry at Fil-Am Baptist Church is broad. We minister to the needy churches in the Philippines by sending balikbayan boxes which include: toiletries, food, first aid kits, clothing, shoes, toys, school supplies, candy, books, Sunday School material, and much more.
            </p>
            <p>
              The Women of the Word encourages women in spiritual growth, discipleship, evangelism, mission, and prayer! <span className="">Join the W.O.W. and be a blessing to others.</span>
            </p>
          </section>
          <div className="two-media-container">
            <div >
              <img src="/wow-media-1.jpg" alt="" />
            </div>
            <div>
              <img src="/wow-media-2.jpg" alt="" />
            </div>
          </div>
          <div className="quote-container quote-container-1">
            <q>
              It is not a program but a ministry. The focus is life-changing discipleship that leads women into a life that reflects Christ through their thoughts, words, and actions.
            </q>
          </div>
        </div>
      </div>
    {/*<Footer />*/}
    </>
  )
}

export default Wow