import React from 'react'
import '../style/contact.css'
import Address from '../assets/filamlocation.png'
import NonHomeBanner from '../components/NonHomeBanner'
import Footer from '../components/Footer'

function Contact() {
  return (
    <>
      <div className='contact-container'>
        {/* <div className='loc'>
          <img src='/filamlocation.png' alt='address'/>
        </div> */}
        <NonHomeBanner title='' imgSrc={Address}/>
        <div className='contact-body'>
          <div className='body-left-wrap'>
            <div className='contact-body-left'>
              <div className='contact-left-tagline noto-serif'>
                Where to find us
              </div>
              <div className='give-title-sub montserrat'>
                CONTACT US
              </div>
              <div className='contact-left-text montserrat'>
                We welcome you at our Sunday services,
                <br/>
                9:30 am Sunday School; 10:30 am Worship Service
                
              </div>

              <br/>
              <div className='contact-icon-text montserrat'>
                (480) 329-9830
              </div>
              <div className='contact-icon-text montserrat'>
                745 N Alma School Rd, AZ 85201
              </div>
              <div className='contact-icon-text montserrat'>
                filambapchurch@aol.com
              </div>
            </div>
          </div>

          <div className='contact-body-right'>
            <div className='input-wrap'>
              <div className='half-input'>

                <input 
                  placeholder='Name'
                  className='montserrat contact-inp'
                />
              </div>
              <div className='half-input'>

                <input 
                  placeholder='E-mail'
                  className='montserrat contact-inp'
                />
              </div>
            </div>

            <div className='input-wrap'>
              <div className='full-input'>

                <input 
                  placeholder='Subject'
                  className='w-97 montserrat contact-inp'
                />
              </div>
            </div>

            <div className='input-wrap'>
              <div className='full-input'>

                <textarea 
                  placeholder='Message'
                  className='w-97 montserrat'
                  rows={8}
                />
              </div>
            </div>
            <button className='contact-form-button' type='submit'> Login </button>
          </div>

        </div>

      </div>
      {/*<Footer />*/}
    </>
  )
}

export default Contact