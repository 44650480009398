import { HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import TopNav from './components/TopNav';
import Home from './components/Home';
import Events from './components/Events';
import Postings from './components/Postings';
import Sermon from './components/Sermon';
import Church from './components/Church';
import Pastor from './components/Pastor';
import Youth from './components/Youth';
import Children from './components/Children';
import Wow from './components/Wow';
import Mop from './components/Mop';
import Tucson from './components/Tucson';
import Frontline from './components/Frontline';
import Give from './pages/Give';
import Contact from './pages/Contact';
import Login from './pages/Login';
import { useRef } from 'react';
import Footer from './components/Footer';

function App() {
  const topRef = useRef(null)

  const handleScrollTop = () => {
    console.log('app ', topRef)
    topRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <HashRouter>
        <TopNav tR={topRef} />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/church' element={<Church/>} />
          <Route path='/pastor' element={<Pastor/>} />
          <Route path='/children' element={<Children/>} />
          <Route path='/youth' element={<Youth/>} />
          <Route path='/wow' element={<Wow/>} />
          <Route path='/mop' element={<Mop/>} />
          <Route path='/give' element={<Give/>} />
          <Route path='/frontline' element={<Frontline/>} />
          <Route path='/tucson' element={<Tucson/>} />
          <Route path='/sermon' element={<Sermon/>} />
          <Route path='/events' element={<Events/>} />
          <Route path='/postings' element={<Postings/>} />
          <Route path='/contact'element={<Contact/>} />
          <Route path='/login-admin'element={<Login/>} />
        </Routes>
        <Footer scrollTop={handleScrollTop}/>
      </HashRouter>
    </>
  );
}

export default App;
