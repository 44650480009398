import React from 'react'
import NonHomeBanner from '../components/NonHomeBanner'
import placeholdImg from '../assets/banner_placehold.jpg'
import Footer from '../components/Footer'
import '../style/give.css'

function Give() {
  return (
    <>
        <NonHomeBanner title='Tithes & Offerings' imgSrc={placeholdImg} />
        <section className='give-sec-title'>
            <span className='give-title-head noto-serif'>Where to find us</span>
            <span className='give-title-sub montserrat'>MAKE A CONTRIBUTION</span>
        </section>
        <section className='three-give-row-sec'>
            <div className='img-give-sec-item'>
                <div className='give-sec-img-container'>
                    <img src='/Venmo.png' />
                    <h3 className='img-row-txt montserrat'>
                        
                    </h3>
                </div>
                <div className='give-img-details'>
                    <div className='give-platform noto-serif'>
                        VENMO
                    </div>
                    <div className='give-platform-handle montserrat'>
                        Venmo Handle Here
                    </div>
                    <div>

                    </div>

                </div>
            </div>

            <div className='img-give-sec-item'>
                <div className='home-sec-img-container'>
                    <img src='/PayPal.png' style={{borderRadius:'.75rem'}}/>
                    <h3 className='img-row-txt montserrat'>
                        
                    </h3>
                </div>
                <div className='give-img-details'>
                    <div className='give-platform noto-serif'>
                        PAYPAL
                    </div>
                    <div className='give-platform-handle montserrat'>
                        Paypal Handle Here
                    </div>
                    <div>

                    </div>

                </div>
            </div>

            <div className='img-give-sec-item'>
                <div className='home-sec-img-container'>
                    <img src='/Zelle2.png' style={{borderRadius:'.75rem'}}/>
                    <h3 className='img-row-txt montserrat'>
                        
                    </h3>
                </div>
                <div className='give-img-details'>
                    <div className='give-platform noto-serif'>
                        ZELLE
                    </div>
                    <div className='give-platform-handle montserrat'>
                        Zelle handle here
                    </div>
                    <div>

                    </div>

                </div>
            </div>
        </section>
         {/* <Footer /> */}
    </>
  )
}

export default Give
