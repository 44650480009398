import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../style/login.css'

export default function Login() {

    const navigate = useNavigate();
    const [ loginForm, setLoginForm ] = useState({
        'user': '',
        'password': ''
    })

    const login = async (e) => {
        e.preventDefault();
        // const { data } = await reilmAPI.getUser(loginForm)
        // if(data && data.error) {
        //   console.log(data.error)
        //   toast.error(data.error)
        //   return setUserData({ 'user': '' })
        // } else {
        //   setUserData({ 'user': data })
        //   localStorage.setItem('user', JSON.stringify(data))
        //   return navigate('/userhome')
        // }
      }

  return (
    <>
        <form onSubmit={login}>
            <div className='login-container my-4 pb-2' >
                <h2 className='montserrat' > LOGIN </h2>
                <label>
                    <input
                        autoFocus 
                        required 
                        name='user' 
                        type='text' 
                        autoComplete='off' 
                        placeholder='User' 
                        // onChange={updateFormInputs} 
                        className={'admin-input1 montserrat'} 
                    />
                </label>
                <label>
                    <input 
                        required 
                        name='password' 
                        type='password' 
                        autoComplete='off' 
                        placeholder='Password' 
                        // onChange={updateFormInputs} 
                        className={'admin-input1 montserrat'}
                    />
                </label>
                <button className='contact-form-button' type='submit'> Login </button>
            </div>
        </form>
    </>
  )
}
