import React from 'react'
import Footer from './Footer'
import '../style/pastor-ministry.css'

function Frontline() {
  return (
    <div>
      <div className="ministry-container">
        <aside>
          <img src="/lea-headshot.jpg" alt="Lea Garcia, Frontline Lead Singer" />
          <div>
            <h3 className="montserrat">Lea Garcia</h3>
            <p className="caption-title">Frontline Leader/Lead Singer</p>
            <p className="leader-desc">
              She sings her heart out for God. She leads us in worship and in life with grace and love. When she leads us, she set aside herself and say, '"Not to us, O LORD, not to us, but to your Name give glory, for the sake of your steadfast love and your faithfulness!" Psalm 115:1
            </p>
          </div>
        </aside>
        <div className='ministry-content-cont'>
          <section>
            <figure>
              <img src="/frontline.png" alt="Group photo of Fil-Am's music ministry" />
              <figcaption className='caption-title'>The Music Ministry</figcaption>
            </figure>
            <h2 className="montserrat">Frontline Band</h2>
            <p>
              At Fil-Am International Baptist Church, we experience a blended worship style during our services. Due to our diverse congregation, the music played during our church service varies ranging from contemporary praise, traditional classic hymns, and gospel music. Our Music ministry's musical instruments consist of a piano, acoustic guitar, electric guitar, bass, drums, keyboard, and electric violin. The music ministry of FAIBC is committed to play music that faithfully fulfills the Gospel of our Lord Jesus Christ. We are Christ-centered, divinely inspired, and dedicated to serving our church and community with our God-given gifts.
            </p>
            <div className="two-media-container">
              <div >
                <img src="/frontline-media-1.jpg" alt="" />
              </div>
              <div>
                <img src="/frontline-media-2.jpg" alt="" />
              </div>
            </div>
            <p>
              We are constantly looking for new singers, musicians, backup singers, and audiovisual tech support to add to the music ministry team. We also encourage children to participate and develop their gifts in music at an early age within the background of worshiping Jesus Christ. 
            </p>
            <p>
              If you are interested to participate in the Music Ministry, feel free to contact our lead singer Lea Garcia.
            </p>
            <div className='quote-container quote-container-2'>
              <q>
                One God. One language. One desire and adoration- to praise and worship Him, in spirit and in truth.
              </q>
            </div>
          </section>
        </div>
      </div>
      {/*<Footer />*/}
    </div>
  )
}

export default Frontline