import React from 'react'
import '../style/pastor-ministry.css'
import Footer from './Footer'
import Banner from '../assets/banner.jpg'

function Tucson() {
  return (
    <>
      <div className="ministry-container">
        <aside>
          <img src='/tucson-headshot.jpg' alt="sample photo" />
          <div>
            <h3 className="montserrat">Filipino-American Christian Fellowship
            </h3>
            <p className="caption-title">Tucson, Arizona</p>
            <p className="leader-desc">
              FAIBC has a daughter church in the greater Tucson, Arizona.
            </p>
          </div>
        </aside>
        <div className='ministry-content-cont'>
          <section>
            <figure>
              <img src={Banner} alt="Group photo of Fil-Am's music ministry" />
              <figcaption className='caption-title'>
                The Music Ministry
              </figcaption>
            </figure>
            <h2 className="montserrat">Filipino-American Christian Fellowship</h2>
            <p>
              On December 17, 2006, the first Bible study was held during Edmund Ignacio’s birthday party in his home in Tucson. Those in attendance were Nick Subong, Alex Gregorios, and Reden Evarola and their wives and children. They met to discuss having Christian fellowship apart from the usual parties and sporting events. The conversation began with a question by Nick, “What is happiness?” They resolved to conduct a monthly Bible study at Fountain Plaza Apartments where most Filipinos working at Tucson Medical Center were based. It was decided Saturday would be the best day.
            </p>
            <p>
              Prior to moving to the area, Edgar Zalamea (who was then in the UK) had contacted Pastor Joel Padilla from the Filipino American Baptist Church in Phoenix to inquire about a Tucson church. Upon arrival, Edgar learned about the home Bible study group. He contacted Pastor Joel again regarding the possibility of having a minister help establish a Filipino church in Tucson. Little did he know, Pastor Joel had been praying for outreach in the area. The first Bible study with Pastor Joel Padilla took place in August of 2007. A Bible study at the apartments took place the following month.
            </p>
            <p>
              On September 29, 2007, a worship service was held at Fountain Plaza Apartments. Approximately 15 members from the Filipino American Baptist Church of Phoenix came to support the outreach. Another 15 or more participants from Tucson were present. Those members were Rommel Caole, RJ Carlos (and his mother-in-law) the Reden Evarola Family, Ely Ferrer, the Edmund Ignacio Family, the Nick Subong Family, Chito, and Gloria Tapia, Edgar Zalamea and a few visitors.
            </p>
          </section>
          <div className='quote-container quote-container-2'>
            <q> 
              "Make every effort to keep the unity of the Spirit through the bond of peace."
              <br/>
              Ephesians 4:3
            </q>
          </div>
          <section>
            <p>
              Weekly church services are held every Sunday at 2 pm at Rincon Baptist Church located at 7500 E. Golf Links Rd., Tucson, AZ 85730.
            </p>
            <p>
              Bible studies are every Friday at 7:00 pm as well as other smaller mid-week Bible studies.
            </p>
            <p>
              Please contact one of the leaders for locations.  We welcome all Christian backgrounds and denominations to be a part of our Filipino Christian community.  Come join us for one of our gatherings!
            </p>
          </section>
        </div>
      </div>
      {/*<Footer />*/}
    </>
  )
}
export default Tucson