import React from 'react'
import Footer from './Footer'
import '../style/pastor-ministry.css'

function Pastor() {
  return (
    <div>
    <div className="ministry-container">
      <aside>
        <img src="/Pastor-headshot.jpg" alt="Joel Padilla" />
        <div>
          <h3 className="montserrat">Joel Padilla</h3>
          <p className="caption-title">Pastor</p>
          <p className="leader-desc">
            Pastor Joel Padilla grew up in the Pampanga province of the Philippines, in the small town of Mexico, near San Fernando.
          </p>
        </div>
      </aside>
      <div className='ministry-content-cont'>
        <section>
          <figure>
              <img src="/Pastor-family.jpg" alt="Pastor's family photo" />
              <figcaption className='caption-title'>Pastor Joel and his family</figcaption>
            </figure>
          <h2 className="montserrat">About</h2>
          <p>
            As a pastor's child, Joel grew up in a Christian home, receiving Christ as his Saviour at a young age. Ever since he was a child, he knew God was calling him into full-time ministry as a pastor. As he was in college, he met his wife, Medick. They married in December 1989.
          </p>
          <p>
            Joel graduated from the seminary in 1991 and became an ordained minister in 1993. After 6 years of serving as pastor at Gethsemane Baptist Church in Guagua, Pampanga, he and his family moved to Mira Mesa San Diego, California.
          </p>
          <p>
            After 6 months, God called him to serve at Fil-Am Baptist Church of Arizona, now Fil-Am International Baptist Church. He and his family moved again and has been serving Christ faithfully in this church since 1996. His wife, Medick, works as a department manager in Walmart. They have a son, JP, who is also the drummer for the church band, and a daughter, Jeuel, who is the keyboard player and the church secretary. They reside in Gilbert, Arizona.
          </p>
        </section>
        <div className="quote-container quote-container-1">
          <q>
            Let the word of Christ dwell in you richly in all wisdom; teaching and admonishing one another
            in psalms and hymns and spiritual songs, singing with grace in your hearts to the Lord.<br/>
            Colossians 3:16
          </q>
        </div>
        <section>
          <h2 className="montserrat">Education</h2>
          <ul>
            <li>
              B.S  Management Industrial Engineering at Holy Angel University, 1987
            </li>
            <li>
              M.Div. Theology at Philippine Baptist Theological Seminary, 1991
            </li>
            <li>
              Clinical Pastoral Education at Banner Desert Hospital 2009
            </li>
          </ul>
        </section>
      </div>
    </div>
    // <Footer />/>
  </div>
  )
}

export default Pastor