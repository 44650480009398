import React from 'react'
import Footer from './Footer'
import '../style/pastor-ministry.css'

function Youth() {
  return (
    <>
      <div className="ministry-container">
        <aside>
          <img src="/paolo-headshot.jpg" alt="Paolo Ranola" />
          <div>
            <h3 className="montserrat">Paolo Ranola</h3>
            <p className="caption-title">Renewed Youth Leader</p>
            <p className="leader-desc">
              The Frontline Band Acoustic/Bass player and the Renewed Youth Leader.
            </p>
          </div>
        </aside>
        <div className='ministry-content-cont'>
          <section>
            <figure>
              <img src="/Renewed-Youth.jpg" alt="The Renewed Youth group photo" />
              <figcaption className='caption-title'>The Renewed Youth</figcaption>
            </figure>
            <h2 className="montserrat">The Renewed Youth</h2>
            <p>
              The exhortation from the apostle Paul to "not conform any longer to the pattern of this world, but be transformed by the renewing of your mind" (Romans 12:2) has been a rallying cry for us at FAIBC of AZ especially the Youth Group.
            </p>
          </section>
          <div className="quote-container quote-container-1">
            <q>
              "But those who hope in the Lord will renew their strength. They will soar on wings like eagles; they will run and not grow weary, they will walk and not be faint." Isaiah 40:31
            </q>
          </div>
          <section>
            <figure>
              <img src="/renewed-youth-1.jpg"alt="Renewed Youth playing games at Paskong Pinoy" />
              <figcaption className='caption-title'>
                Renewed Youth playing games at Paskong Pinoy
              </figcaption>
            </figure>
            <p>
              The Renewed Youth is designed to level the playing field of Christian Youth. It is a fellowship where the music, language, and intentions in the atmosphere are to glorify God. A fellowship where it's cool to be a Christian. A place for Christian young people to bring their unsaved friends to experience the Gospel.
            </p>
          </section>
        </div>
      </div>
      {/*<Footer />*/}
    </>
  )
}

export default Youth