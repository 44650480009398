import React from 'react'
import '../style/events.css'
import Calendar from './Calendar'
import { ReactComponent as FindIcon } from '../assets/find.svg'

function Events() {

  const handleSearch =()=> {

  }
  return (
    <div className='event-container'>
      <div className='search-container'>
        <FindIcon className='pad-l'/>
        <input 
          className='search-event' 
          placeholder='Search for events'
        />
        <button className='search-btn' onClick={handleSearch}>Find Event</button>
        <div className='event-view-wrap'> Month </div>
        <div className='event-view-wrap'> List </div>
        <div className='mar-r'>

        </div>
      </div>
      <Calendar 
        views={[ 'month' ]}
        toolbar={false}
      />
    </div>
  )
}

export default Events
