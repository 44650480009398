import React from 'react'
import Footer from './Footer'
import '../style/pastor-ministry.css'

function Children() {

  return (
    <>
      <div className="ministry-container">
        <aside>
          <img src="/michelle-headshot.jpg" alt="sample photo" />
          <div>
            <h3 className="montserrat">Michelle Vizcarra</h3>
            <p className="caption-title">Children Ministry Team Leader</p>
            <p className="leader-desc">
              As children are an important part of our church community, Fil-Am International Baptist Church has a Sunday school program led by Michelle Vizcarra.
            </p>
          </div>
        </aside>
        <div className='ministry-content-cont'>
          <section>
            <figure>
              <img src="/Children.jpg" alt="children sunday school" />
              <figcaption className='caption-title'>Children Arts & Crafts during Sunday School</figcaption>
            </figure>
            <h2 className="montserrat">Children's Sunday School</h2>
            <p>
              This is a time when children can have a ministry dedicated to their age group. The children's ministry time during the Sunday service is led by volunteers helping out on a rotational basis. The church also sponsors a yearly Vacation Bible School program during the summer as an outreach to children in the East Valley and the surrounding communities.
            </p>
            <p>
              Fil-Am Baptist Church Sunday School wouldn’t be possible without the support and ministry of our dedicated volunteers.
            </p>
          </section>
          <div className="quote-container quote-container-2">
            <q>
              "I have no greater joy than to hear that my children are walking in the truth." 
              <br/>
              John 1:4
            </q>
          </div>
          <div className="two-media-container">
            <div >
              <img src="/children-media-1.jpg" alt="" />
            </div>
            <div>
              <img src="/children-media-2.jpg" alt="" />
            </div>
          </div>
          <section>
            <h2 className="montserrat">
              Sunday School Leadership Team 
            </h2>
            <p>
              The Sunday School Leadership Team is a group of volunteers teachers, shepherds, storytellers, and group leaders and we’re always excited to welcome new members to the team. Volunteers are provided ample opportunities for growth, fellowship, and support. We’re happy to welcome leaders from a wide range of background and experience.
            </p>
            <p>
              Contact us if you would like to volunteer to be a Sunday School teacher!
            </p>
          </section>
        </div>
      </div>
      {/*<Footer />*/}
    </>
  )
}

export default Children